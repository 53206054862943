<template>
  <div class="hotLine_warp">
    <div class="info_bg">
      <!-- title -->
      <a-row type="flex" justify="center" align="middle" style="padding-bottom: 20px;">
        <a-col :span="24" type="flex" justify="center" align="center"
               style="font-size: 4rem; color: #fafafa; font-weight: bolder; align-items: center;">软控IT运维中心
        </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="middle">
        <a-col :span="8" type="flex" justify="center" align="center"
               style="font-size: 20px; color: #fafafa; font-weight: bolder; align-items: center;">输入您的问题，找到解决办法
        </a-col>
      </a-row>

      <!-- search -->
      <a-row type="flex" justify="center" align="middle" style="margin-top: 25px">
        <a-col :span="12">
<!--          <a-input placeholder="请搜索您出现的问题，寻找解决方案，输入完毕后请按会车键" v-model="searchInfo" @pressEnter="doSearch()" />-->
          <a-input-search class="search" placeholder="请搜索您出现的问题，寻找解决方案，输入完毕后请按回车键" v-model="searchInfo" enter-button @search="doSearch" />
        </a-col>
      </a-row>

      <!-- 功能区 -->
      <a-row :gutter="64" style="padding: 30px 120px 30px 120px; ">
        <!-- 我遇到的问题 -->
        <a-col :span="6">
          <div type="flex" justify="center" align="center" class="functionClass" @click="goNewQusPage()">
            <img class="tab_img" src="@/assets/img/systemOps-question.png">
            <h2>新的问题</h2>
          </div>
        </a-col>

        <!-- 找到解决方案 -->
        <a-col :span="6">
          <div type="flex" justify="center" align="center" class="functionClass" @click="goDocumentPage('questions')">
            <img class="tab_img" src="@/assets/img/systemOps-data.png">
            <h2>问题贴士</h2>
          </div>
        </a-col>

        <!-- 学习系统使用 -->
        <a-col :span="6">
          <div type="flex" justify="center" align="center" class="functionClass" @click="goDocumentPage('documents')">
            <img class="tab_img" src="@/assets/img/systemOps-reading.png">
            <h2>知识库</h2>
          </div>
        </a-col>

        <!-- 我不满意 -->
        <a-col :span="6">
          <div type="flex" justify="center" align="center" class="functionClass" @click="showNewQusModal(false)">
            <img class="tab_img" src="@/assets/img/systemOps-question.png">
            <h2>投诉建议</h2>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-row class="contextAreaRow" :gutter="8" type="flex" justify="center">
      <a-col :span="20">
        <a-card :loading="loading" :bordered="true"
                :headStyle="{ backgroundColor: '#ecf0f1',border: '1px solid #c0c0c0'}">
          <div slot="title">
            <a-icon type="alert" theme="filled" style="font-size: 20px; color: #e57000" />
            <span style="margin-left: 5px; font-size: 20px;font-weight: bolder; color: #2c3e50;">公告栏</span>
          </div>
          <a-list item-layout="horizontal" :data-source="noticeInfo">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta description="">
                <a v-if="item.type==='noticeSystemOps'" slot="title"
                   @click="$router.push({path:'details', query:{ id:item.id }})">
                  <a-icon type="notification" />
                  {{ item.title }}</a>
                <a v-if="item.type==='noticeQuestionPaper'" slot="title"
                   @click="$router.push({path:'questionnaireInfo', query:{ questionnaireId:item.id,questionnaireUserInstanceId:item.questionnaireUserInstanceId }})">
                  <a-icon type="ordered-list" />
                  {{ item.title }} <span v-if="item.questionnaireUserInstanceId == null" style="color: red;">new!!!</span> </a>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </a-card>
      </a-col>
    </a-row>

    <a-row class="contextAreaRow" :gutter="8" type="flex" justify="center">

      <a-col :span="14">
        <a-card :loading="loading" :bordered="true"
                :headStyle="{ backgroundColor: '#ecf0f1',border: '1px solid #c0c0c0'}">
          <div slot="title">
            <a-row type="flex" align="middle">
              <a-col :span="20">
                <a-icon type="global" style="font-size: 20px; color: #2dad9a " />
                <span style="margin-left: 5px; font-size: 20px;font-weight: bolder;">我待审批的问题</span>
              </a-col>
              <a-col :span="4">
                <div style="text-decoration:underline; cursor:pointer;" @click="moreInfo(1)">
                  <a-icon type="double-right" />
                  <span style="font-size: 15px;">更多</span>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-table
            :columns="needAuditQuestionColumns"
            :row-key="record => record.id"
            :data-source="needAuditQuestionDataInfo"
            :customRow="needAuditQuestionRowClick"
          >
            <a-icon slot="icon" type="tag" />
            <a slot="requirementsProblem" slot-scope="requirementsProblem,record"
               target="_blank" :href="record.workFlowUrl">
              {{ requirementsProblem }}
            </a>
          </a-table>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card :loading="loading" :bordered="true"
                :headStyle="{ backgroundColor: '#ecf0f1',border: '1px solid #c0c0c0'}">
          <div slot="title">
            <a-row type="flex" align="middle">
              <a-col :span="20">
                <a-icon type="table" style="font-size: 20px; color: #be0e2e; " />
                <span style="margin-left: 5px; font-size: 20px;font-weight: bolder;">我的投诉建议</span>
              </a-col>
              <a-col :span="4">
                <div style="text-decoration:underline; cursor:pointer;" @click="moreInfo(1)">
                  <a-icon type="double-right" />
                  <span style="font-size: 15px;">更多</span>
                </div>
              </a-col>
            </a-row>
          </div>
          <!--          <a-list item-layout="horizontal" :data-source="myQuestionDataInfo">-->
          <!--            <a-list-item slot="renderItem" slot-scope="item">-->
          <!--              <a-list-item-meta :description="item.activityName">-->
          <!--                <a-icon type="tag" />-->
          <!--                <a slot="title" slot-scope="suggestionDesc,record"-->
          <!--                   @click="showNewQusModal(true,record.id)">-->
          <!--                  {{ suggestionDesc }}</a>-->
          <!--              </a-list-item-meta>-->
          <!--              <div class="ellipsisText">{{ item.createName }}</div>-->
          <!--            </a-list-item>-->
          <!--          </a-list>-->
          <a-table
            :columns="suggestionDataInfoColumns"
            :row-key="record => record.id"
            :data-source="unProcessSuggestionDataInfo"
          >
            <a-icon slot="icon" type="tag" />
            <a slot="suggestionDesc" slot-scope="suggestionDesc,record"
               @click="showNewQusModal(true,record.id)">
              {{ suggestionDesc }}</a>
          </a-table>
        </a-card>
      </a-col>
    </a-row>

    <a-modal
      ref="newQusModal"
      :title="newQusModalTitle"
      :visible="newQusModalVisible"
      :confirm-loading="newQusConfirmLoading"
      :maskClosable="false"
      centered
      :ok-button-props="{ props: { disabled : newQusModalSubmitDisabledFlag } }"
      @ok="newQusHandleOk"
      @cancel="newQusHandleCancel"
    >
      <div>
        <a-form-model ref="newQusForm"
                      :model="newQusForm"
                      :label-col="{ span: 4 }"
                      :wrapper-col="{ span: 20 }"
                      :rules="newQusFormRules">
          <a-form-model-item prop="systemType" label="投诉对象" required>
            <a-input placeholder="投诉对象" v-model="newQusForm.systemType" />
          </a-form-model-item>
          <a-form-model-item prop="suggestionDesc" label="问题描述" required>
            <a-textarea :disabled="newQusModalSubmitDisabledFlag"
                        placeholder="请输入问题描述"
                        :auto-size="{ minRows: 6, maxRows: 6 }"
                        v-model="newQusForm.suggestionDesc"
            />
          </a-form-model-item>
          <a-form-model-item label="相关截图">
            <a-upload
              :action="url.fileUploadUrl"
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
              :disabled="newQusModalSubmitDisabledFlag"
            >
              <div v-if="fileList.length < 4">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>


          <a-form-model-item v-if="newQusModalSubmitDisabledFlag" prop="processStatus" label="处理状态" required>
            <a-select default-value="0" v-model="newQusForm.processStatus" disabled>
              <a-select-option value="0">未处理</a-select-option>
              <a-select-option value="1">已处理</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="newQusModalSubmitDisabledFlag" prop="processDesc" label="处理反馈">
            <a-textarea :auto-size="{ minRows: 6, maxRows: 6 }" v-model="newQusForm.processDesc" disabled />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
    import { getAction, httpAction, postAction } from '@/api/manage'
    import { USER_INFO } from '@/store/mutation-types'
    import { MESNAC_SYSTEM_OPS_URL } from './config'
    import Vue from 'vue'

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    export default {
        data() {
            return {
                unProcessSuggestionLoading: false,
                sorter:{},
                unProcessSuggestionPagination: {
                    pageSize: 5,
                    current:1,
                    total:6
                },
                unProcessSuggestionDataInfo: [],
                // 我的投诉建议
                suggestionDataInfoColumns: [
                    {
                        title: '',
                        dataIndex: 'icon',
                        width: '5%',
                        scopedSlots: { customRender: 'icon' }
                    },
                    {
                        title: '问题描述',
                        dataIndex: 'suggestionDesc',
                        width: '70%',
                        scopedSlots: { customRender: 'suggestionDesc' },
                        ellipsis: true
                    },
                    {
                        title: '投诉对象',
                        dataIndex: 'systemType',
                        ellipsis: true
                    }
                ],
                url: MESNAC_SYSTEM_OPS_URL,
                userInfo: Vue.ls.get(USER_INFO),
                searchInfo: null,
                visible: false,
                loading: true,
                loadingNum: 6,
                data: {},
                myQuestionDataInfo: [],

                // 需要我审批的流程
                needAuditQuestionDataInfo: [],
                needAuditQuestionColumns: [
                    {
                        title: '',
                        scopedSlots: { customRender: 'icon' },
                        width: '5%'
                    },
                    {
                        title: '问题描述',
                        dataIndex: 'requirementsProblem',
                        ellipsis: true,
                        scopedSlots: { customRender: 'requirementsProblem' }
                    },
                    {
                        title: '处理节点',
                        dataIndex: 'activityName',
                        ellipsis: true
                    },
                    {
                        title: '处理人',
                        dataIndex: 'currentProcessors',
                        ellipsis: true
                    },
                    {
                        title: '到达日期',
                        dataIndex: 'currentProcessStartTime'
                    }
                ],
                needAuditQuestionLoading: true,

                noticeInfo: [],
                // 提交问题流程Url
                submitQuestionBpmUrl: '',

                // 新增问题建议不可用标志（新增 false，查看 true）
                newQusModalSubmitDisabledFlag: false,
                newQusModalTitle: '',
                newQusModalVisible: false,
                newQusConfirmLoading: false,

                // 问题表单
                newQusForm: {},
                // 问题表单规则
                newQusFormRules: {
                    systemType: [
                        { required: true, message: '请选择系统', trigger: 'change' }
                    ],
                    suggestionDesc: [
                        { required: true, message: '请填写问题描述', trigger: 'blur' }
                    ]
                },
                // 系统信息
                systemInfos: [],

                // 文件上传&预览
                previewVisible: false,
                previewImage: '',
                fileList: []
            }
        },
        watch: {
            loadingNum: {
                handler(newValue, oldValue) {
                    console.log('loadingNum', newValue)
                    if (newValue <= 0) {
                        this.loading = false
                    } else {
                        this.loading = true
                    }
                }
            }
        },
        created() {
            //this.loadHotLine()
            this.loadNoticeInfo()
        },
        methods: {
            unProcessSuggestionFetch(params = {}) {
                this.unProcessSuggestionLoading = true

                getAction(this.url.querySuggestionList, {
                    createBy: this.userInfo.username,
                    processStatus: '0',
                    ...params
                }).then(res => {
                    if (res.success) {
                        const pagination = { ...this.unProcessSuggestionPagination }
                        pagination.total = res.result.total

                        this.unProcessSuggestionLoading = false
                        this.unProcessSuggestionDataInfo = res.result.records
                        this.unProcessSuggestionPagination = pagination

                        // console.log('unProcessSuggestionFetch ', this.unProcessSuggestionDataInfo)
                    } else {
                        this.$message.warn('获取未处理投诉建议异常：' + res.message)
                    }
                })
            },
            moreInfo(type) {
                console.log(type)
                this.$router.push({ path: 'hotLineMyList' })
            }
            ,
            loadHotLine() {
                this.loading = true
                getAction(this.url.helpTellList).then(res => {
                    if (res.success) {
                        this.data = res.result.records[0].remark
                        this.loading = false
                    }
                })
            },
            // 查看文档接页面
            goDocumentPage(type) {
                let url = this.url.mesnacSystemOpsUrl + '/' + type + '/?authid=' + this.userInfo.id
                window.open(url)
            },

            // 执行查询
            doSearch() {
                let url = this.url.mesnacSystemOpsUrl + '/?authid=' + this.userInfo.id + '&query=' + this.searchInfo
                window.open(url)
            },

            ////////////////////////////
            // 新建问题
            // 新建问题流程
            // 查看文档接页面
            goNewQusPage() {
                var tempwindow = window.open('_blank')
                tempwindow.location = this.submitQuestionBpmUrl
            },

            // 需审批问题代办
            needAuditQuestionRowClick(record) {
                return {
                    on: {
                        click: () => {
                            // console.log('123123123qqqq', record)
                        }
                    }
                }
            },

            // 新建问题Model弹出
            showNewQusModal(viewFlag, suggestionId) {
                this.newQusModalTitle = viewFlag ? '投诉建议' : '新建投诉建议'
                this.newQusModalVisible = true
                this.newQusModalSubmitDisabledFlag = false
                this.fileList = []
                this.newQusForm = {}

                if (viewFlag) {
                    // 不能提交
                    this.newQusModalSubmitDisabledFlag = true
                    getAction(this.url.querySuggestionById, { id: suggestionId }).then(res => {
                        if (res.success) {
                            this.newQusForm = res.result
                            let attachmentFile = this.newQusForm.attachmentFile
                            if (attachmentFile !== null && attachmentFile !== undefined && attachmentFile !== '') {
                                // 设置已上传文件
                                //let fileNames = attachmentFile.substring(attachmentFile.lastIndexOf(",")+1).split(',')
                                let fileNames = attachmentFile.split(',')
                                fileNames.forEach((fileName, index) => {
                                    if (fileName != '') {
                                        this.fileList.push({
                                            uid: '-' + index,
                                            name: fileName,
                                            status: 'done',
                                            url: this.url.fileViewUrl + fileName
                                        })
                                    }
                                })
                            }
                        }
                    })
                }
            },
            // 提交问题 & 投诉建议
            newQusHandleOk(e) {
                this.$refs.newQusForm.validate(valid => {
                    if (valid) {
                        let that = this
                        this.$confirm({
                            title: '确认',
                            content: '提交投诉建议?',
                            onOk: function() {
                                console.log('newQusHandleOk:', e)
                                that.newQusconfirmLoading = true
                                console.log('newQusForm', that.newQusForm)
                                console.log('fileList', that.fileList)
                                let attachmentFile = ''
                                that.fileList.forEach(fileInfo => {
                                    if (fileInfo.response !== null && fileInfo.response.success) {
                                        attachmentFile += fileInfo.response.message + ','
                                    } else {
                                        attachmentFile += fileInfo.filename + ','
                                    }
                                })
                                // 附件信息
                                that.newQusForm.attachmentFile = attachmentFile
                                // 录入人信息
                                that.newQusForm.createName = that.userInfo.realname
                                that.newQusForm.createBy = that.userInfo.username
                                that.newQusForm.sysOrgCode = that.userInfo.orgCode

                                httpAction(that.url.addSuggestion, that.newQusForm, 'post').then(res => {
                                    if (res.success) {
                                        that.$message.success('提交成功')
                                        that.newQusModalVisible = false
                                    } else {
                                        that.$message.warning('提交错误[' + res.message + ']')
                                    }
                                })
                            }
                        })

                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            },
            newQusHandleCancel(e) {
                console.log('newQusHandleCancel:', e)
                console.log('Clicked cancel button')
                this.newQusModalVisible = false
            },

            //////////////////////////////////////////////////
            // 上传图片相关方法
            previewHandleCancel() {
                this.previewVisible = false
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj)
                }
                this.previewImage = file.url || file.preview
                this.previewVisible = true
            },
            handleChange(info) {
                let successFlagInfo = true
                if (info.file.status === 'done') {
                    if (info.file.response.success) {
                        this.$message.success(`${info.file.name} 文件上传成功`)
                    } else {
                        this.$message.error(`${info.file.name} 文件上传失败`)
                        successFlagInfo = false
                    }
                } else if (info.file.status === 'error') {
                    this.$message.error(`${info.file.name} 文件上传失败`)
                    successFlagInfo = false
                }

                this.fileList = info.fileList
                if (!successFlagInfo) {
                    this.fileList.forEach((item, index, arr) => {
                        if (item.uid === info.file.uid) {
                            this.fileList.splice(index, 1)
                        }
                    })
                }
            },
            //////////////////////////////////////////////////

            loadNoticeInfo() {
                this.unProcessSuggestionFetch();
                // 1、获取问题提交流程信息
                getAction(this.url.getQuestionBpmUrlInfo, { 'userName': this.userInfo.username }).then(res => {
                    if (res.success) {
                        this.submitQuestionBpmUrl = res.result
                    } else {
                        this.$message.warn(res.message)
                    }
                    this.loadingNum--
                })

                // 2、获取字典类
                getAction(this.url.getSoSystemTypeDictItems).then(res => {
                    if (res.success) {
                        this.systemInfos = res.result
                        console.log('systemInfos', this.systemInfos)
                    } else {
                        this.$message.warn(res.message)
                    }
                    this.loadingNum--
                })

                // 3、获取调查问卷
                getAction(this.url.listAllValidQuestionnaire, {
                    userName: this.userInfo.username
                }).then(res => {
                    if (res.success) {
                        console.log('listAllValidQuestionnaire:', res.result)
                        res.result.forEach((value) => {
                            let noticeQuestionPaper = {
                                id: value.questionnaireId,
                                type: 'noticeQuestionPaper',
                                title: value.questionnaireName + '[调查时间：' + value.beginTime + '至' + value.endTime + ']',
                                questionnaireUserInstanceId: value.questionnaireUserInstanceId
                            }
                            this.noticeInfo.push(noticeQuestionPaper)
                        })
                    } else {
                        this.$message.warn(res.message)
                    }
                    this.loadingNum--
                })

                // 4、获取-运维服务平台公告
                getAction(this.url.zmaPrescenterList, {
                    pageNo: 1,
                    pageSize: 10,
                    pressColumn: '运维服务平台公告',
                    presssTatus: '已发布',
                    column: 'releasetime',
                    order: 'desc'
                }).then(res => {
                    if (res.success) {
                        res.result.records.forEach((value) => {
                            let noticeSystemOps = {
                                id: value.id,
                                type: 'noticeSystemOps',
                                title: value.pressTitle,
                                releaseTime: value.releasetime
                            }
                            this.noticeInfo.push(noticeSystemOps)
                        })
                    } else {
                        this.$message.warn(res.message)
                    }
                    this.loadingNum--
                })


                // getAction(this.url.querySuggestionList, {
                //     createBy: this.userInfo.username,
                //     processStatus: '0'
                // }).then(res => {
                //     if (res.success) {
                //         this.myQuestionDataInfo = res.result.records;
                //         console.log("querySuggestionList")
                //         console.log(this.myQuestionDataInfo)
                //     } else {
                //         this.$message.warn('获取未处理投诉建议异常：' + res.message)
                //     }
                // })

                // 5、获取-获取用户提交流程分页
                getAction(this.url.querySuggestionList, {
                    pageNo: 1,
                    pageSize: 5,
                    userName: this.userInfo.username
                }).then(res => {
                    if (res.success) {
                        this.myQuestionDataInfo = res.result.records
                        console.log('myQuestionDataInfo:', this.myQuestionDataInfo)
                    } else {
                        this.$message.warn(res.message)
                    }
                    this.loadingNum--
                })

                // 6、获取-获取用户待审核流程分页
                getAction(this.url.queryUserNeedAuditQuestionsPage, {
                    pageNo: 1,
                    pageSize: 6,
                    userName: this.userInfo.username
                }).then(res => {
                    if (res.success) {
                        this.needAuditQuestionDataInfo = res.result.records
                        console.log('needAuditQuestionDataInfo:', this.needAuditQuestionDataInfo)
                    } else {
                        this.$message.warn(res.message)
                    }
                    this.loadingNum--
                })

            }
        }
    }
</script>
<style lang="less" scoped>
  .height-80 {
    height: 80px;
  }

  .info_bg {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABOSURBVFhH7c6xCQAgDAVRR9A6E4hLu4uLiWJ7tSnuQcIvr2TRYsw3/zOGGEOMIcYQY4gxxBhiDDGGGEOMIcYQY4gxxBhiDLkx52W4Gn1tuslCtHJvL54AAAAASUVORK5CYII=") rgb(40, 40, 45);
    padding: 80px 20px 80px 20px;
    margin-bottom: 20px;
  }

  .functionClass {
    h2 {
      color: #ffffff;
      margin-top: 10px;
    }
  ;

    img {
      width: 10rem;
      height: 10rem;
      transition: all 500ms linear;
    }
  }

  .functionClass:hover {
    cursor: pointer;

    h2 {
      color: #11a8cd
    }
  ;

    img {
      transform: scale(1.1);
      transition: all 500ms linear;
    }
  }

  .hotLine_warp {
    width: 100%;
    padding-bottom: 25px;
    position: relative;
    // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
    background: #f6f6f6;

    .hotLine_content {
      min-height: 800px;
      width: 1559px;
      margin: 0 auto;
      background-color: #fff;
      padding: 30px;

      .hotLine_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .title {
          font-size: 22px;
          color: rgba(16, 16, 16, 100);
          font-family: SourceHanSansSC-bold;
        }

        .close {
          cursor: pointer;

          .icon {
            font-weight: 600;
            font-size: 22px;
          }
        }
      }
    }

    .ellipsisText {
      width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
      text-align: right;
    }

    .contextAreaRow {
      margin: 0 0 20px 0;
    }

    /deep/ .ant-pagination {
      display: none;
    }


  }
</style>

